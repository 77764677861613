import type { FullBlockRichTextFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import { ContentfulRichText } from '../contentful/ContentfulRichText'

export const RichTextBlock: FunctionComponent<React.PropsWithChildren<{
  block: FullBlockRichTextFragment
  customProps?: any
}>> = ({ block, customProps }) => {
  const { richText, textAlignCenter, superscript, superscriptFontSize } = block
  const blackBackground = !!customProps?.blackBackground
  return (
    <>
      <ContentfulRichText
        textAlignCenter={textAlignCenter}
        richText={richText}
        superscript={superscript}
        superscriptFontSize={superscriptFontSize}
        forceWhiteFont={blackBackground}
      />
    </>
  )
}
